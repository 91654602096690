<template>
	<Panel ref="menuPanel" />
	<TitleBar :isChat="false" :title="title" @goBack="goBack" @showMenu="showMenu" @changeTitle="changeTitle" />
	<div class="list scrollable">
		<Conversation v-for="item in list" :item="item" @select="onClick" @delete="onDelete" />
	</div>
	<div ref="loading" class="loading"></div>
	<Inputter ref="inputter" @onInput="onInput" />
</template>

<style scoped>
.list {
	position: absolute;
	z-index: 0;
	left: 0px;
	right: 0px;
	top: 64px;
	bottom: 0px;
	padding: 15px;
	padding-top: 20px;
	padding-bottom: 50px;
	background-image: linear-gradient(150deg, rgba(45, 12, 19, 1.0) 0%, rgba(47, 47, 53, 1.0) 65%, rgba(62, 56, 65, 1.0) 100%);
}
.loading {
	position: absolute;
	z-index: 9999;
	top: 0px;
	left: 0px;
	width: 0%;
	height: 0%;
	background-color: rgba(255, 255, 255, 0);
	backdrop-filter: blur(0px);
	transition: background-color 300ms ease-in-out, backdrop-filter 300ms ease-in-out, width 300ms step-end, height 300ms step-end;
}
.loading.show {
	width: 100%;
	height: 100%;
	background-color: rgba(127, 127, 127, 0.3);
	backdrop-filter: blur(5px);
	transition: background-color 300ms ease-in-out, backdrop-filter 300ms ease-in-out, width 300ms step-start, height 300ms step-start;
}
</style>

<script>
import TitleBar from '@/components/titleBar.vue';
import Conversation from '@/components/conversation.vue';
import Inputter from '@/components/inputter.vue';
import Panel from '@/components/panel.vue';

var current;
window.onSettingChanged = evt => {
	if (!current) return;
	current.onSettingChanged(evt);
};

export default {
	name: 'Conversations',
	components: {
		TitleBar,
		Conversation,
		Inputter,
		Panel
	},
	data () {
		return {
			title: "AI个人健康助手",
			list: []
		}
	},
	mounted () {
		current = this;

		var list = [];
		this.aiExpert = localStorage.get("AIExpert", DefaultExpert);
		this.onSettingChanged({
			key: 'AIExpert',
			value: this.aiExpert
		});

		for (let key in localStorage) {
			if (!key.match(/^chat\-/)) continue;
			list.push(localStorage.get(key));
		}
		list.sort((a, b) => b.time - a.time);
		this.list = list;

		this.$refs.inputter.reset();
		this.$refs.inputter.focusInput();
	},
	unmounted () {
		if (current === this) current = null;
	},
	methods: {
		async onInput (content) {
			if (this.runSysCmd(content)) {
				this.$refs.inputter.reset();
				this.$refs.inputter.focusInput();
				return;
			}

			this.$refs.inputter.inactive();
			this.$refs.loading.classList.add('show');

			try {
				let result = await sendRequest('/conversation', {
					event: 'new',
					model: localStorage.get("AIModel", DefaultAIModel),
					level: localStorage.get("AILevel", DefaultAILevel),
					expert: localStorage.get("AIExpert", DefaultExpert),
				});
				console.log(result);

				this.$refs.inputter.active();
				this.$refs.loading.classList.remove('show');

				localStorage.set('chat-' + result, {
					id: result,
					title: content,
					time: Date.now(),
					model: localStorage.get("AIModel", DefaultAIModel),
					level: localStorage.get("AILevel", DefaultAILevel),
					expert: localStorage.get("AIExpert", DefaultExpert),
					history: [[0, content]]
				});
				this.$router.push({
					path: '/chat/' + result,
				});
			}
			catch (err) {
				notify(err.message || err.msg || err.data || err, 'AI Error', 'top-right', 'error', 3000);
				this.$refs.inputter.active();
				this.$refs.loading.classList.remove('show');
			}
		},
		onClick (id) {
			this.$router.push({
				path: '/chat/' + id,
			});
		},
		goBack () {
			this.$router.push({ path: '/' });
		},
		showMenu () {
			this.$refs.menuPanel.show();
		},
		async onDelete (title) {
			this.list = [];

			let list = [];
			for (let key in localStorage) {
				if (!key.match(/^chat\-/)) continue;
				let item = localStorage.get(key);
				if (item.title === title) {
					localStorage.removeItem(key);
				}
				else {
					list.push(item);
				}
			}
			list.sort((a, b) => b.time - a.time);

			await wait(100);
			this.list = list;
		},
		runSysCmd (cmd) {
			if (cmd.match(/^\/setLevel/)) {
				let lev = cmd.replace(/^\/setLevel[\s:=]*/, '');
				if (!!lev) {
					lev = lev * 1;
					if (lev >= 0) {
						localStorage.set("AILevel", lev);
						notify('AI Level Changed', 'Change Setting', 'top-right', 'success', 2000);
						this.$refs.inputter.reset();
						this.$refs.inputter.focusInput();
						return true;
					}
				}
				return true;
			}
			else if (cmd.match(/^\/getLevel$/)) {
				let level = localStorage.get("AILevel", 1);
				notify('AI Level: ' + level, 'Setting', 'top-right', 'message', 2000);
				return true;
			}
			else if (cmd.match(/^\/setModel/)) {
				let model = cmd.replace(/^\/setModel[\s:=]*/, '');
				if (!!model) {
					localStorage.set("AIModel", model);
					notify('AI Model Changed', 'Change Setting', 'top-right', 'success', 2000);
					this.$refs.inputter.reset();
					this.$refs.inputter.focusInput();
					return true;
				}
				return true;
			}
			else if (cmd.match(/^\/getModel$/)) {
				let model = localStorage.get("AIModel", DefaultAIModel);
				notify('AI Model: ' + model, 'Setting', 'top-right', 'message', 2000);
				return true;
			}
			else if (cmd.match(/^\/setExpert/)) {
				let exp = cmd.replace(/^\/setExpert[\s:=]*/, '');
				if (!!exp) {
					localStorage.set("AIExpert", exp);
					notify('AI Expert Chagned', 'Change Setting', 'top-right', 'success', 2000);
					this.$refs.inputter.reset();
					this.$refs.inputter.focusInput();
					return true;
				}
				return true;
			}
			else if (cmd.match(/^\/getExpert$/)) {
				let expert = localStorage.get("AIExpert", 'health');
				notify('AI Expert: ' + expert, 'Setting', 'top-right', 'message', 2000);
				return true;
			}
			else if (cmd.match(/^\/remove/)) {
				let target = cmd.replace(/^\/remove[\s:=]*/, '');
				if (!target) return true;
				this.onDelete(target);
				return true;
			}
			else if (cmd.match(/^\/clear$/)) {
				for (let key in localStorage) {
					if (!key.match(/^chat\-/)) continue;
					localStorage.removeItem(key);
				}
				this.list.splice(0);
				return true;
			}
			else if (cmd.match(/^\/help reset$/)) {
				this.resetAbout();
				return true;
			}
			else if (cmd.match(/^\/help markup$/)) {
				this.$router.push({
					path: '/about',
					query: { markup: true }
				});
				return true;
			}
			else if (cmd.match(/^\/help$/)) {
				this.$router.push({
					path: '/about',
				});
				return true;
			}
			else if (cmd.match(/^\/console$/)) {
				this.$router.push({
					path: '/console',
				});
				return true;
			}
			return false;
		},
		onSettingChanged (evt) {
			if (evt.key === 'AIExpert') {
				if (evt.value === 'health') {
					this.title = "AI个人健康助手";
				}
				else if (evt.value === 'science') {
					this.title = "AI个人科研助手";
				}
				else {
					this.title = "AI个人助理";
				}
			}
			this.$refs.menuPanel.onSettingUpdate();
		},
		async resetAbout () {
			var result = await sendRequest('/about', 'reset');
			notify('Cache Resetted', 'Help Center', 'top-right', 'success', 2000);
		},
	},
};
</script>
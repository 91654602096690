<template>
	<div class="conversation" :style="{backgroundImage: backColor}" :id="id" @click="onClick">
		<div class="title">{{title}}</div>
		<div class="info">能力：{{level}};　领域：{{expert}};　模型：{{model}};　记录：{{length}}条;　更新：{{time}}</div>
		<div class="deleter" @click.stop="onDelete"><i class="fas fa-trash-alt"></i></div>
	</div>
</template>

<style scoped>
.conversation {
	position: relative;
	margin-bottom: 20px;
	padding: 15px 20px;
	border-radius: 15px;
	background-color: rgb(103, 143, 247);
	box-shadow: inset 0px 0px 1px black, 0px 5px 10px -3px rgba(248, 244, 237, 0.5);
	color: rgba(248, 244, 237, 1.0);
	text-align: left;
	cursor: pointer;
}
.conversation:hover {
	box-shadow: inset 0px 0px 1px white, 0px 5px 10px -3px rgba(248, 244, 237, 1.0);
}
.conversation .title {
	margin-bottom: 0px;
	margin-right: 30px;
	overflow: hidden;
	text-overflow: ellipsis;
	word-break: keep-all;
	white-space: nowrap;
	text-shadow: 1px 1px 2px rgba(57, 57, 57, 1.0);
}
.conversation:hover .title {
	margin-bottom: 10px;
	overflow: unset;
	text-overflow: unset;
	word-break: normal;
	white-space: normal;
}
.conversation .info {
	display: none;
	color: rgba(204, 204, 214, 1.0);
	font-style: italic;
	text-shadow: 1px 1px 2px rgba(115, 115, 115, 1.0);
}
.conversation:hover .info {
	display: block;
}
.conversation .deleter {
	position: absolute;
	top: 15px;
	right: -35px;
	font-size: 20px;
	cursor: pointer;
}
.conversation:hover .deleter {
	right: 15px;
}
</style>

<script>
const LevelList = {
	"0": "素人AI",
	"1": "面向提示语的AI",
	"2": "面向插件的AI",
	"3": "面向任务的AI"
};
const ModelList = {
	"claude-2.1": "Claude-2.1",
	"claude-instant-1.2": "Claude-1.2",
	"gpt-4-1106-preview": "GPT-4.0",
	"gpt-3.5-turbo-1106": "GPT-3.5",
};
const ExpertList = {
	"health": "个人健康助理",
	"science": "科研助手"
};
const LevelColor = {
	"0": "rgba(19, 24, 36, 1.0)",
	"1": "rgba(18, 170, 156, 1.0)",
	"2": "rgba(18, 107, 174, 1.0)",
	"3": "rgba(155, 30, 100, 1.0)",
};
const ModelColor = {
	"claude-2.1": "rgba(23, 241, 202, 1.0)",
	"claude-instant-1.2": "rgba(133, 43, 174, 1.0)",
	"gpt-4-1106-preview": "rgba(202, 23, 241, 1.0)",
	"gpt-3.5-turbo-1106": "rgba(174, 133, 43, 1.0)",
};
const ExpertColor = {
	"health": "rgba(237, 81, 38, 1.0)",
	"science": "rgba(111, 51, 129, 1.0)",
};

export default {
	name: 'Conversation',
	props: ["item"],
	data () {
		return {
			id: '',
			title: 'untitled',
			time: '0000/00/00',
			length: 0,
			level: 1,
			expert: '',
			model: '',
			backColor: '',
		}
	},
	methods: {
		onClick () {
			this.$emit('select', this.id);
		},
		onDelete () {
			this.$emit('delete', this.item.title);
		}
	},
	mounted () {
		this.title = this.item.title;
		this.id = this.item.id;
		this.time = getTimeString(this.item.time, "YYYY/MM/DD");
		this.length = this.item.history.length;
		this.level = LevelList[(this.item.level || 1) - 1] || this.item.level;
		this.expert = ExpertList[this.item.expert || DefaultExpert] || this.item.expert;
		this.model = ModelList[this.item.model || DefaultAIModel] || this.item.model;

		var lc = LevelColor[this.item.level - 1] || LevelColor['0'];
		var vc = ModelColor[this.item.model] || ModelColor[DefaultAIModel];
		var ec = ExpertColor[this.item.expert] || ExpertColor.health;
		this.backColor = `linear-gradient(150deg, ${lc} 0%, ${vc} 50%, ${ec} 100%)`;
	}
}
</script>
<template>
	<div class="inputter">
		<div ref="inputter" class="input scrollable" contenteditable
			@keydown.stop
			@keyup.stop
			@keydown.enter.prevent="send"
			@paste.prevent="onPaste" />
		<div class="sender" @click="send"><i class="fas fa-paper-plane"></i></div>
	</div>
</template>

<style scoped>
.inputter {
	position: absolute;
	left: 10px;
	right: 10px;
	bottom: 20px;
	min-height: 50px;
	max-height: 500px;
	padding: 15px 20px;
	background-color: rgba(248, 244, 237, 1.0);
	box-shadow: 0px -2px 10px 2px rgba(127, 127, 135, 0.5), 0px 5px 10px -3px rgb(127, 127, 135);
	border: 1px solid rgb(56, 56, 64);
	border-radius: 15px;
	z-index: 1;
}
.inputter > .input {
	margin-left: 0px;
	margin-right: 40px;
	color: rgb(56, 56, 64);
	text-align: left;
	font-size: 16px;
	line-height: 20px;
	outline: none;
	transition: color 300ms ease-in-out;
}
.inputter > .input:empty, .inputter > .input::placeholder {
	content: "请输入你想问的问题：";
	color: rgb(97, 97, 127);
}
.inputter.disable > .input {
	pointer-events: none;
	color: rgb(156, 156, 156);
}
.inputter > .sender {
	position: absolute;
	top: 10px;
	right: 20px;
	font-size: 24px;
	cursor: pointer;
	transition: color 300ms ease-in-out;
}
.inputter.disable > .sender {
	color: rgb(156, 156, 156);
}
</style>

<script>
export default {
	name: 'Inputter',
	methods: {
		focusInput () {
			var sel = document.getSelection();
			sel.removeAllRanges();
			var range = document.createRange();
			range.setStart(this.$refs.inputter, 0);
			range.setEnd(this.$refs.inputter, 0);
			sel.addRange(range);
		},
		send (evt) {
			if (evt.ctrlKey || evt.shiftKey || evt.AltKey || evt.metaKey) {
				document.execCommand('insertParagraph');
				return;
			}
			var content = this.$refs.inputter.innerText;
			content = content.replace(/^\s*|\s*$/gi, '');
			if (!content) return;
			this.$emit('onInput', content);
		},
		onPaste (e) {
			const text = e.clipboardData.getData('text/plain');
			document.execCommand('insertText', false, text);
		},
		reset () {
			this.$refs.inputter.innerHTML = '';
		},
		active () {
			this.$el.classList.remove('disable');
			this.$refs.inputter.contentEditable = true;
		},
		inactive () {
			this.$el.classList.add('disable');
			this.$refs.inputter.contentEditable = false;
		}
	}
};
</script>
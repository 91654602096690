<template>
	<div class="titlebar">
		<div v-if="isChat || !isBlank" class="item button left" @click="goBack"><i class="fa-solid fa-angle-left"></i></div>
		<div v-if="isChat" class="item button right" @click="download"><i class="fas fa-download"></i></div>
		<div v-if="isBlank" class="item button right" @click="clearConversation"><i class="fas fa-eraser"></i></div>
		<div v-if="!isChat && !isBlank" class="item button right" @click="showMenu"><i class="fas fa-bars"></i></div>
		<div ref="title" class="item title" :contenteditable="isChat && !isBlank" @keydown.enter.prevent="onInput">{{title}}</div>
		<div class="float scrollable">
			<slot></slot>
		</div>
	</div>
</template>

<style scoped>
.titlebar {
	position: relative;
	padding: 15px 10px;
	background-image: linear-gradient(150deg, rgba(31, 32, 64, 1.0), rgba(46, 49, 124, 1.0));
	border-bottom: 1px solid black;
	box-shadow: 0px 2px 5px rgb(68, 68, 68);
	color: rgba(248, 244, 237, 1.0);
	font-size: 25px;
	font-weight: bolder;
}
.titlebar > .item {
	display: block;
}
.titlebar > .item.button {
	position: relative;
	top: 5px;
	text-align: left;
	cursor: pointer;
}
.titlebar > .item.button.left {
	float: left;
	margin-left: 20px;
}
.titlebar > .item.button.right {
	float: right;
	margin-right: 20px;
}
.titlebar > .item.title {
	height: 29px;
	margin-left: 50px;
	margin-right: 50px;
	text-align: center;
	outline: none;
	overflow: hidden;
	word-break: keep-all;
	white-space: nowrap;
	text-overflow: ellipsis;
	cursor: default;
	user-select: none;
}
.titlebar > .item.title[contenteditable="true"] {
	cursor: text;
	user-select: auto;
}
.titlebar > .item.title:focus {
	height: auto;
	overflow: visible;
	word-break: break-all;
	white-space: normal;
	text-overflow: ellipsis;
}
.titlebar .float {
	position: absolute;
	display: none;
	left: 120px;
	right: 120px;
	top: 60px;
	min-height: 100px;
	max-height: 200px;
	padding: 15px;
	border-radius: 0px 0px 10px 10px;
	background-color: rgba(23, 23, 64, 0.8);
	box-shadow: 5px 5px 10px rgba(23, 23, 23, 0.6);
	font-size: 16px;
	font-weight: lighter;
	text-align: left;
	white-space: break-spaces;
	color: white;
	text-shadow: 1px 1px 1px black;
}
.titlebar .float:empty {
	display: none;
}
.titlebar:hover .float {
	display: block;
}
.titlebar:hover .float:empty {
	display: none;
}
</style>

<script>
export default {
	name: 'TitleBar',
	props: {
		title: String,
		isChat: Boolean,
		isBlank: Boolean,
	},
	methods: {
		goBack () {
			this.$emit('goBack');
		},
		showMenu () {
			this.$emit('showMenu');
		},
		download () {
			this.$emit('download');
		},
		clearConversation () {
			this.$emit('clearConversation');
		},
		onInput (evt) {
			var title = this.$refs.title.innerText;
			if (!title) {
				this.$refs.title.innerText = this.title;
				return;
			}
			this.$emit('changeTitle', title);
		}
	}
}
</script>
<template>
	<div class="panel" :show="visible">
		<div class="closer" @click="visible=false"><i class="fas fa-times-circle"></i></div>
		<div class="area scrollable">
			<div class="line">
				<label>工作模型：</label>
				<select v-model="ai" @change="changeAI">
					<option v-for="model in models" :value="model">{{model}}</option>
				</select>
			</div>
			<div class="line">
				<label>能力等级：</label>
				<select v-model="level" @change="changeLevel">
					<option value="0">素人AI</option>
					<option value="1">面向提示语的AI</option>
					<option value="2">面向插件的AI</option>
					<option value="3">面向任务的AI</option>
				</select>
			</div>
			<div class="line">
				<label>专业领域：</label>
				<select v-model="expert" @change="changeExpert">
					<option value="health">个人健康助理</option>
					<option value="science">科研助手</option>
				</select>
			</div>
		</div>
	</div>
</template>

<style scoped>
.panel {
	display: none;
	position: absolute;
	z-index: 999;
	top: 0px;
	bottom: 0px;
	left: 0px;
	right: 0px;
	background-color: rgba(75, 75, 75, 0.6);
	backdrop-filter: blur(2px);
	box-shadow: inset 2px 2px 5px rgba(225, 225, 225, 0.4), inset -2px -2px 5px rgba(25, 25, 25, 0.4);
	color: rgba(248, 244, 237, 1.0);
	text-shadow: 1px 1px 2px black;
	text-align: left;
	user-select: none;
}
.panel[show="true"] {
	display: block;
}
.panel .closer {
	position: absolute;
	top: 15px;
	right: 15px;
	padding: 3px;
	font-size: 25px;
	cursor: pointer;
}
.panel .area {
	position: absolute;
	top: 60px;
	left: 50px;
	right: 50px;
	bottom: 20px;
	overflow: auto;
	padding: 25px;
	border-radius: 10px;
	background-color: rgba(205, 205, 205, 0.25);
	box-shadow: inset 2px 2px 5px rgba(25, 25, 25, 0.6);
	font-size: 20px;
}
.panel .area .line {
	margin-bottom: 50px;
}
.panel .area .line:first-child {
	margin-top: 80px;
}
.panel .area label {
	display: inline-block;
	min-width: 100px;
	text-align: right;
}
.panel .area select, .panel .area select option {
	width: 160px;
	color: rgba(248, 244, 237, 1.0);
	text-shadow: 1px 1px 2px black;
}
.panel .area select {
	padding: 2px 5px;
	border: none;
	border-bottom: 1px solid rgba(248, 244, 237, 1.0);
	background-color: transparent;
	outline: none;
	font-size: 18px;
}
.panel .area select option {
	background-color: black;
	font-size: 16px;
}
.panel .area select option::selection {
	background-color: red;
}
@media (max-width: 600px) {
	.panel .area {
		top: 60px;
		left: 20px;
		right: 20px;
		bottom: 20px;
		padding: 15px;
	}
	.panel .area .line {
		margin-bottom: 20px;
	}
	.panel .area .line:first-child {
		margin-top: 50px;
	}
}
</style>

<script>
export default {
	name: 'Panel',
	props: [],
	data () {
		return {
			visible: false,
			models: [],
			ai: "",
			level: 1,
			expert: 'health',
		}
	},
	async mounted () {
		this.onSettingUpdate();
		onEsc(this.onEsc);

		this.models = await this.getModelList();
		this.ai = this.models[0];
	},
	unmounted () {
		offEsc(this.onEsc);
	},
	methods: {
		show () {
			this.visible = true;
		},
		hide () {
			this.visible = false;
		},
		changeAI () {
			localStorage.set('AIModel', this.ai);
			// localStorage.set('AIVersion', this.ai);
		},
		changeLevel () {
			localStorage.set('AILevel', this.level * 1);
		},
		changeExpert () {
			localStorage.set('AIExpert', this.expert);
		},
		onSettingUpdate () {
			this.ai = localStorage.get('AIModel', DefaultAIModel);
			this.level = localStorage.get('AILevel', DefaultAILevel);
			this.expert = localStorage.get('AIExpert', DefaultExpert);
		},
		onEsc () {
			this.visible = false;
		},
		async getModelList () {
			var list = [];
			try {
				let result = sessionStorage.get('modelList', null);
				if (!!result) return result;

				result = await sendRequest('/conversation', {
					event: 'list'
				});
				console.log(result);
				list = result;
				sessionStorage.set('modelList', list);
			}
			catch (err) {
				console.error(err);
				notify({
					title: "数字化身获取失败",
					message: err.message || err.msg || err.data || err,
					duration: 5000,
					type: "error"
				});
			}
			return list;
		},
	},
}
</script>